<template>
  <div class="person-quick-view-actions text-md" :style="actionsStyle">
    <div class="action hints" @click="clickShowHints()" v-if="showHints">
      <div class="hints-icon"><img :src="hintsIconSrc" /></div>
      <div>{{ hintsLabel }}</div>
      <div class="hotkey"><key-cup class="dark" :size="14">H</key-cup></div>
    </div>
    <hr class="separator desktop-only" v-if="showHints" />
    <div class="action" @click="clickFullProfile()" v-if="showFullProfile">
      <profile-icon></profile-icon>
      <div v-tooltip="fullProfileTooltip">Full Profile</div>
      <div class="hotkey"><key-cup class="dark" :size="14">P</key-cup></div>
    </div>
    <div class="action" v-if="editable" @click="clickQuickEdit()">
      <pencil-icon></pencil-icon>
      <div>Quick Edit</div>
      <div class="hotkey"><key-cup class="dark" :size="14">E</key-cup></div>
      <arrow-icon class="arrow-icon mobile-only" :size="24" />
    </div>
    <div class="action" v-if="editableFull" @click="fullProfileEditClick()">
      <account-edit-icon></account-edit-icon>
      <div>Full Edit</div>
      <div class="hotkey"><key-cup class="dark" :size="14">F</key-cup></div>
    </div>
    <div class="action mobile-only" v-if="showAddRelative" @click="openAddRelativeModal">
      <plus-icon></plus-icon>
      <div v-tooltip="addRelativeTooltip">Add Relative</div>
      <arrow-icon class="arrow-icon" :size="24" />
    </div>
    <div class="action mobile-only" v-if="!expanded" @click="openMoreActionsModal">
      <dots-icon :size="22"></dots-icon>
      <div>More Actions</div>
      <arrow-icon class="arrow-icon" :size="24" />
    </div>

    <div class="action has-submenu desktop-only" v-if="showAddRelative">
      <div class="action" @click="toggleAddRelativeSubmenu()">
        <plus-icon></plus-icon>
        <div>Add Relative</div>
        <div class="hotkey"><key-cup class="dark" :size="14">R</key-cup></div>
      </div>

      <div class="submenu" :style="relativesSubMenuStyle">
        <div v-if="relativesLoading" class="sub-action loading-message">Fetching relatives...</div>
        <template v-else>
          <div class="sub-action" :class="{disabled: !canAddParents}" @click="clickAddParent()">
            <div>Parent</div>
            <div class="hotkey"><key-cup class="dark" :size="14">1</key-cup></div>
          </div>
          <div class="sub-action" @click="clickAddSpouse()">
            <div>Spouse</div>
            <div class="hotkey"><key-cup class="dark" :size="14">2</key-cup></div>
          </div>
          <div class="sub-action" @click="clickAddSibling()">
            <div>Sibling</div>
            <div class="hotkey"><key-cup class="dark" :size="14">3</key-cup></div>
          </div>
          <div class="sub-action" @click="clickAddChild()">
            <div>Child</div>
            <div class="hotkey"><key-cup class="dark" :size="14">4</key-cup></div>
          </div>
        </template>
      </div>
    </div>

    <div class="action more desktop-only" v-if="!expanded" @click="expandActions">
      <more-icon :size="22"></more-icon>
      <div>More Actions</div>
    </div>
    <template v-if="expanded">
      <div class="action" key="view-tree" v-if="showViewTree" @click="clickViewTree()">
        <eye-icon></eye-icon>
        <div>View Tree</div>
        <div class="hotkey"><key-cup class="dark" :size="14">T</key-cup></div>
      </div>
      <div class="action" key="view-lineage" v-if="showViewLineage" @click="clickViewLineage()">
        <lineage-icon></lineage-icon>
        <div>View Lineage</div>
        <div class="hotkey"><key-cup class="dark" :size="14">L</key-cup></div>
      </div>
      <div class="action" key="delete" v-if="removable" @click="clickDelete()">
        <delete-icon></delete-icon>
        <div>Delete</div>
        <div class="hotkey"><key-cup class="dark" :size="14">DEL</key-cup></div>
      </div>
    </template>
  </div>
</template>

<script>
import KeyCup from '@common/elements/icons/KeyCup';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import ProfileIcon from 'vue-material-design-icons/Account';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit';
import MoreIcon from 'vue-material-design-icons/ChevronDown';
import ArrowIcon from 'vue-material-design-icons/ChevronRight';
import DeleteIcon from 'vue-material-design-icons/Delete';
import DotsIcon from 'vue-material-design-icons/DotsHorizontalCircleOutline';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import EyeIcon from 'vue-material-design-icons/Sitemap';

import {
  CARD_ADD_RELATIVE_HELPER_KEY,
  CARD_FULL_PROFILE_HELPER_KEY,
  CONTEXT_MENU_SECTIONS,
} from '@/components/modules/familyTree/constants';
import {isQuickSidebarHotkeyDisabled} from '@/components/modules/familyTree/tree/services.modals';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

export default {
  props: {
    sections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    hintsCount: {type: Number, required: false, default: 0},
    canAddParents: Boolean,
    relativesLoading: Boolean,
    personName: String,
    enableHotkeys: {type: Boolean, default: true},
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  data() {
    return {
      expanded: false,
      showAddRelativeSubmenu: false,
      keyP: 80,
      keyE: 69,
      keyT: 84,
      keyL: 76,
      keyR: 82,
      keyH: 72,
      keyF: 70,
      key1: 49,
      key2: 50,
      key3: 51,
      key4: 52,
      keyDel: 46,
      keyBackspace: 8,
    };
  },
  computed: {
    actionsStyle() {
      let maxHeight = this.hintsCount ? 400 : 300;
      if (this.showAddRelativeSubmenu) {
        maxHeight += 200;
      }
      if (this.expanded) {
        maxHeight += 500;
      }
      return {'max-height': `${maxHeight}px`};
    },
    relativesSubMenuStyle() {
      let maxHeight = this.showAddRelativeSubmenu ? 200 : 0;
      return {'max-height': `${maxHeight}px`};
    },
    showFullProfile() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
    },
    removable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    editable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    editableFull() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
    },
    showAddRelative() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    showViewTree() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE);
    },
    showViewLineage() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE);
    },
    disableHotkeys() {
      return !this.enableHotkeys || Boolean(this.$store.getters.galleryDisplayItemState);
    },
    hintsLabel() {
      return `Review ${this.hintsCount} Record Match${this.hintsCount > 1 ? 'es' : ''}`;
    },
    hintsIconSrc() {
      return this.$getAsset('/assets/mcr_logo_dude_transparent.png');
    },
    showHints() {
      return this.hintsCount;
    },
    fullProfileTooltip() {
      return {
        popperClass: 'text-center horizontal-bounce',
        theme: 'tooltip-onboarding',
        placement: 'right',
        triggers: [],
        container: false,
        shown: this.$store.getters.showCardFullProfileTooltipTooltip,
        distance: 20,
        skidding: -2,
        delay: {show: 200},
        content: 'Click for more details',
      };
    },
    addRelativeTooltip() {
      return {
        popperClass: 'text-center horizontal-bounce',
        theme: 'tooltip-onboarding',
        placement: 'right',
        triggers: [],
        container: false,
        shown: this.$store.getters.showCardAddRelativeTooltipState,
        distance: 20,
        skidding: -2,
        delay: {show: 200},
        content: 'Add a relative',
      };
    },
  },
  methods: {
    expandActions() {
      this.trackClick('More Actions');
      this.expanded = true;
    },
    toggleAddRelativeSubmenu(hotkey) {
      this.trackClick('Add Relative', hotkey);
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    onKeyDown(e) {
      if (this.disableHotkeys || isQuickSidebarHotkeyDisabled()) {
        return;
      }
      console.log(e.which);
      if (e.which === this.keyP && this.showFullProfile) {
        this.clickFullProfile(true);
      }
      if (e.which === this.keyE && this.editable) {
        this.clickQuickEdit(true);
      }
      if (e.which === this.keyR && this.showAddRelative) {
        this.toggleAddRelativeSubmenu(true);
      }
      if (e.which === this.keyH && this.showHints) {
        this.clickShowHints(true);
      }
      if (e.which === this.key1 && this.showAddRelative) {
        this.clickAddParent(true);
      }
      if (e.which === this.key2 && this.showAddRelative) {
        this.clickAddSpouse(true);
      }
      if (e.which === this.key3 && this.showAddRelative) {
        this.clickAddSibling(true);
      }
      if (e.which === this.key4 && this.showAddRelative) {
        this.clickAddChild(true);
      }
      if (e.which === this.keyT && this.showViewTree) {
        this.clickViewTree(true);
      }
      if (e.which === this.keyL && this.showViewLineage) {
        this.clickViewLineage(true);
      }
      if (e.which === this.keyF && this.editableFull) {
        this.fullProfileEditClick(true);
      }
      if ((e.which === this.keyDel || e.which === this.keyBackspace) && this.removable) {
        this.clickDelete(true);
      }
    },
    clickFullProfile(hotkey) {
      this.trackClick('Full Profile', hotkey);
      this.$emit('click-full-profile');
    },
    clickQuickEdit(hotkey) {
      this.trackClick('Quick Edit', hotkey);
      this.$emit('click-quick-edit');
    },
    fullProfileEditClick(hotkey) {
      this.trackClick('Full Edit', hotkey);
      this.$emit('click-full-profile-edit');
    },
    clickViewTree(hotkey) {
      this.trackClick('View Tree', hotkey);
      this.$emit('click-view-tree');
    },
    clickViewLineage(hotkey) {
      this.trackClick('View Lineage', hotkey);
      this.$emit('click-view-lineage');
    },
    clickDelete(hotkey) {
      this.trackClick('Delete', hotkey);
      this.$emit('click-delete');
    },
    clickAddParent(hotkey) {
      if (!this.canAddParents) {
        return this.$toasted.error(`Could not add parent because ${this.personName} already has two parents`);
      }
      this.trackClick('Parent', hotkey);
      this.$emit('click-add-parent');
    },
    clickAddSpouse(hotkey) {
      this.trackClick('Spouse', hotkey);
      this.$emit('click-add-spouse');
    },
    clickAddSibling(hotkey) {
      this.trackClick('Sibling', hotkey);
      this.$emit('click-add-sibling');
    },
    clickAddChild(hotkey) {
      this.trackClick('Child', hotkey);
      this.$emit('click-add-child');
    },
    clickShowHints(hotkey) {
      this.trackClick('Review Hints', hotkey);
      this.$emit('click-show-hints');
    },
    openAddRelativeModal() {
      this.$store.commit('setShowCardAddRelativeTooltipState', false);
      this.$store.commit('addReviewedItemState', CARD_ADD_RELATIVE_HELPER_KEY);
      if (!this.$store.getters.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY)) {
        this.$store.commit('setShowCardFullProfileTooltipState', true);
      }
      this.trackClick('Add Relative', null);
      this.$emit('open-add-relative-modal');
    },
    openMoreActionsModal() {
      this.trackClick('More Actions', null);
      this.$emit('open-more-actions-modal');
    },
    trackClick(label, hotkey) {
      AnalyticsAmplitudeHandler.trackTreeQuickMenuActionClickEvent(label, hotkey);
    },
  },
  components: {
    KeyCup,
    AccountEditIcon,
    ProfileIcon,
    PencilIcon,
    PlusIcon,
    MoreIcon,
    EyeIcon,
    LineageIcon,
    DeleteIcon,
    DotsIcon,
    ArrowIcon,
  },
  name: 'PersonQuickViewActions',
};
</script>

<style lang="scss" scoped>
.person-quick-view-actions {
  transition: max-height 0.5s ease;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px solid $border-color-light;
  border-bottom: 1px solid $border-color-light;
  overflow: hidden;

  .action {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    position: relative;

    .material-design-icon {
      color: $icon-grey;
      margin-right: 20px;
      margin-top: -2px;
    }
    .hotkey {
      margin-left: auto;
      color: $supplemental-text-color;

      .keycup {
        margin: 0;
      }
    }

    &.has-submenu {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin: 0;
    }

    &:not(.has-submenu):hover {
      cursor: pointer;
      background: $background-color;
    }
  }
  .action.hints {
    font-weight: bold;
    color: $mcr-red;

    .hints-icon {
      background: $power-red;
      border-radius: 50%;
      margin-right: 20px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-left: 1px;
        height: 23px;
      }
    }
  }
  .separator {
    margin: 6px 0;
  }

  .submenu {
    transition: max-height 0.3s ease;
    overflow: hidden;
    background: $background-alternate-color;

    .sub-action {
      padding: 10px 10px 10px 50px;
      display: flex;
      align-items: center;

      &.disabled {
        color: $supplemental-text-color;
      }

      &:hover {
        cursor: pointer;
        &:not(.disabled) {
          background: $background-color;
        }
      }
    }
  }

  .loading-message {
    font-style: italic;
  }

  .desktop-only {
    display: flex;
  }
  .mobile-only {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding: 0;
    border: none;

    .action {
      padding: 14px 16px;
      border-bottom: 1px solid $neutral-200;

      .arrow-icon {
        margin: 0 0 0 auto;
        color: $neutral-400;
      }
    }

    .action.has-submenu {
      border-bottom: none;
    }

    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: flex;
    }
  }
}
</style>

<template>
  <div class="spouses-selector-modal-content" @mousedown.left.stop>
    <div class="heading">
      <div class="text-lg">{{ name }}’s Spouses</div>
      <close-icon :size="20" @click="close"></close-icon>
    </div>
    <div class="content">
      <div
        class="spouse-item"
        :class="{'inactive-spouse': isMainSpouseInactive}"
        v-if="defaultSpouse"
        @click="toggleSpouse(defaultSpouse)"
      >
        <div class="checkbox-wrapper">
          <input type="checkbox" :checked="isDefaultSpouseChecked" :disabled="isMainSpouseInactive" />
        </div>
        <div class="icon-wrapper">
          <person-icon-colored :gender="defaultSpouse.gender" :size="28"></person-icon-colored>
        </div>
        <div class="spouse-name">
          {{ getDisplayName(defaultSpouse) }}
        </div>
      </div>
      <div v-for="spouse in spouses" class="spouse-item" @click="toggleSpouse(spouse)">
        <div class="checkbox-wrapper">
          <input type="checkbox" v-model="selected" :id="spouse.object_id" :value="spouse.object_id" />
        </div>
        <div class="icon-wrapper">
          <person-icon-colored :gender="spouse.gender" :size="28"></person-icon-colored>
        </div>
        <div class="spouse-name">
          {{ getDisplayName(spouse) }}
        </div>
      </div>
      <div class="error" v-if="!isDefaultSpouseChecked && !selected.length">Please select at least one spouse</div>
      <div class="button-container">
        <mcr-button :disabled="isButtonDisabled" @click="applyChanges">Apply Changes</mcr-button>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import CloseIcon from 'vue-material-design-icons/Close';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    person: Object,
    name: String,
    controller: Object,
  },
  data() {
    const spouses = this.person.relatives.spouses.filter(p => this.person.other_spouses.includes(p.object_id));
    const renderedSpousesIds = this.$store.getters.familyTreePersonsDrawnState
      .filter(p => this.person.other_spouses.includes(p.object_id))
      .map(p => p.object_id);
    const defaultSpouses = this.person.relatives.spouses.filter(p => !this.person.other_spouses.includes(p.object_id));
    const defaultSpouse = defaultSpouses && defaultSpouses.length ? defaultSpouses[0] : null;

    return {
      selected: [...renderedSpousesIds],
      renderedSpousesIds: renderedSpousesIds,
      spouses: spouses,
      defaultSpouse: defaultSpouse,
      isDefaultSpouseChecked: true,
    };
  },
  computed: {
    isButtonDisabled() {
      if (!this.isDefaultSpouseChecked && !this.selected.length) {
        return true;
      }
      if (!this.isDefaultSpouseChecked && this.selected.length) {
        return false;
      }
      if (this.renderedSpousesIds.length !== this.selected.length) {
        return false;
      }
      for (let i = 0; i < this.renderedSpousesIds.length; i++) {
        if (this.renderedSpousesIds[i] !== this.selected[i]) {
          return false;
        }
      }
      return true;
    },
    isMainSpouseInactive() {
      return !this.person.main_spouse_control || !this.$store.getters.isUserOwnerFamilyTreeState;
    },
    newDefaultSpouseId() {
      if (this.isDefaultSpouseChecked) {
        return null;
      }
      return this.selected ? this.selected[this.selected.length - 1] : null;
    },
  },
  methods: {
    getDisplayName(spouse) {
      return spouse.full_name || UNKNOWN_NAME;
    },
    toggleSpouse(spouse) {
      const isDefaultSpouse = this.defaultSpouse && spouse.object_id === this.defaultSpouse.object_id;
      if (isDefaultSpouse && this.isMainSpouseInactive) {
        return;
      }
      if (isDefaultSpouse) {
        this.isDefaultSpouseChecked = !this.isDefaultSpouseChecked;
        return;
      }
      if (this.selected.includes(spouse.object_id)) {
        this.selected = this.selected.filter(pk => pk !== spouse.object_id);
      } else {
        this.selected = [...this.selected, spouse.object_id];
      }
    },
    applyChanges() {
      const defaultSpouse = this.newDefaultSpouseId
        ? {
            action: 'main-couple',
            controller_state: [this.newDefaultSpouseId, this.person.object_id],
          }
        : null;
      const otherSpouses = {
        controller_id: this.person.controller_ids,
        controller_type: 'other_spouses',
        controller_state: this.selected,
      };
      const dataList = defaultSpouse ? [otherSpouses, defaultSpouse] : [otherSpouses];
      this.$store
        .dispatch('treeSetExpandControlAction', {
          treeId: this.$store.getters.familyTreeDetailsIdState,
          data_list: dataList,
        })
        .then(() => {
          this.close();
          this.$store.dispatch('fetchFamilyTreeMapDiffAction', {
            id: this.$store.getters.familyTreeDetailsIdState,
            start_person_id: this.$store.getters.familyTreeStartPersonIdState,
            map_hash: this.$store.getters.familyTreeMapImageHashState,
          });
        });
    },
    close() {
      this.$emit('close');
    },
  },
  components: {PersonIconColored, McrButton, CloseIcon},
  name: 'SpousesSelectorModalContent',
};
</script>

<style scoped lang="scss">
.spouses-selector-modal-content {
  width: 248px;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    .close-icon {
      cursor: pointer;
      color: $neutral-400;
    }
  }

  .spouse-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        cursor: pointer;
        margin: 0;
      }
    }

    .icon-wrapper {
      width: 40px;
      height: 40px;
      margin: 0 10px 0 16px;
      flex-shrink: 0;
    }
    .spouse-name {
      word-break: break-word;
    }

    &:hover {
      background: $neutral-100;
    }

    &.inactive-spouse {
      color: $neutral-500;
      cursor: not-allowed;
    }
  }

  .error {
    margin: 16px 16px 0;
  }

  .button-container {
    padding: 16px;
  }
}
</style>
